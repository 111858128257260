import { useQuery } from "@tanstack/react-query";
import { TreasuryAccount } from "models/treasury";
import { useAuthenticatedRequest } from "./useRequests";

const useTreasuryAccounts = (userId?: string) => {
    const url = userId
        ? `/treasury/accounts/${userId}`
        : `/treasury/accounts`;

    const getAccounts = useAuthenticatedRequest<TreasuryAccount[]>({
        method: "get",
        url
    });

    return useQuery(["treasury", "accounts", userId], getAccounts, {
        enabled: !!userId
    });
};

export default useTreasuryAccounts;